import { CreateInitiativeFromTemplateV2Config, FullStorySearchBrowseConfig, FullStorySearchConfig } from "./types";

const reportFullStorySearchEvent = (config: FullStorySearchConfig) => {
  if (!window.FS) {
    return;
  }
  const { hashedUserId, isEntitled, resultsCount, searchTerm } = config;
  const payload = {
    hashedUserId,
    isEntitled,
    resultsCount,
    searchTerm: searchTerm?.toLocaleLowerCase(),
  };
  window.FS.event("template search event", payload);
};

const storeFullStorySearchBrowseEvent = (config: FullStorySearchBrowseConfig) => {
  sessionStorage.setItem("template_hub_search_browse", JSON.stringify(config));
};

const reportFullStoryTemplateCreateEvent = (config: CreateInitiativeFromTemplateV2Config) => {
  if (!window.FS) {
    return;
  }
  const { categoryId, categoryName, id, isSearchData, name, searchResultsCount, searchTerm } = config;
  const payload = {
    Mode: isSearchData ? "Search" : "Browse",
    "Search Results Count": searchResultsCount || 0,
    "Search Term": searchTerm || "",
    "Template Category ID": categoryId,
    "Template Category Name": categoryName,
    "Template ID": id,
    "Template Name": name,
  };
  window.FS.event("use template btn click", payload);
};

export { reportFullStorySearchEvent, reportFullStoryTemplateCreateEvent, storeFullStorySearchBrowseEvent };
