export const profileOptions = Object.freeze({
  roleList: [
    "Customer Service",
    "Engineering",
    "Finance",
    "Human Resources",
    "Information Technology",
    "Marketing",
    "Operations",
    "Procurement",
    "Product Management",
    "Sales",
    "Supply Chain",
    "Other",
  ],
  companySizeList: ["1 - 50", "51 - 200", "201 - 500", "501 - 1,000", "1,001 - 5,000", "5,000+"],
  industryList: [
    "Automotive",
    "Construction",
    "Consulting",
    "Consumer Goods",
    "Education",
    "Energy",
    "Financial Services",
    "Government",
    "Health Care",
    "Insurance",
    "Internet",
    "Manufacturing",
    "Media and Advertising",
    "Pharmaceutical",
    "Real Estate",
    "Retail",
    "Software",
    "Technology",
    "Telecommunications",
    "Transportation",
  ],
});
