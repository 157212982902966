// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jliTQ74t9kleDHmgbgAJ{padding-right:81px}.jliTQ74t9kleDHmgbgAJ .Ll5AJw0UljsfQHEecV1y{padding-bottom:56px}.jliTQ74t9kleDHmgbgAJ .B6QGWBio5pJrSHkcrCXw{display:flex;align-items:center;gap:15px;padding-bottom:12px}.jliTQ74t9kleDHmgbgAJ .l4BXDrq0ar7RW0GSbPBg{color:var(--color-neutrals-quartz);width:400px}.jliTQ74t9kleDHmgbgAJ .UWdIZjYmcU8S3sxaL6ba{width:400px;margin-bottom:56px}.jliTQ74t9kleDHmgbgAJ .ZpNV1ZX6nV0JSvCKsGqg{background-color:var(--color-white);height:1px;opacity:.2;margin-bottom:12px}.jliTQ74t9kleDHmgbgAJ .kLBTXLHIsbmVzBiLwZiJ{margin-bottom:16px}.jliTQ74t9kleDHmgbgAJ .h6bq2JmAPGToCmmyvxkg .TH13JYdKLQkI6XrFw4y3{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/researchSlideIn/components/market-guide-body.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,4CACE,mBAAA,CAEF,4CACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,kCAAA,CACA,WAAA,CAGF,4CACE,WAAA,CACA,kBAAA,CAEF,4CACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CAEF,4CACE,kBAAA,CAGA,kEACE,WAAA","sourcesContent":[".contentBodyMidSectionLeft {\n  padding-right: 81px;\n  & .gx-gartner-mg-desc-text {\n    padding-bottom: 56px;\n  }\n  .contentBodyMidSectionTitleText {\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  .contentBodyMidSectionDescText {\n    color: var(--color-neutrals-quartz);\n    width: 400px;\n  }\n\n  .contentBodyMidSectionDescTextGCOM3523 {\n    width: 400px;\n    margin-bottom: 56px;\n  }\n  .contentLearnMoreLine {\n    background-color: var(--color-white);\n    height: 1px;\n    opacity: 0.2;\n    margin-bottom: 12px;\n  }\n  .contentBodyMidSectionLearnMore {\n    margin-bottom: 16px;\n  }\n  .contentLink {\n    .contentBodyMidSectionLinkText {\n      width: 320px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentBodyMidSectionLeft": `jliTQ74t9kleDHmgbgAJ`,
	"gx-gartner-mg-desc-text": `Ll5AJw0UljsfQHEecV1y`,
	"contentBodyMidSectionTitleText": `B6QGWBio5pJrSHkcrCXw`,
	"contentBodyMidSectionDescText": `l4BXDrq0ar7RW0GSbPBg`,
	"contentBodyMidSectionDescTextGCOM3523": `UWdIZjYmcU8S3sxaL6ba`,
	"contentLearnMoreLine": `ZpNV1ZX6nV0JSvCKsGqg`,
	"contentBodyMidSectionLearnMore": `kLBTXLHIsbmVzBiLwZiJ`,
	"contentLink": `h6bq2JmAPGToCmmyvxkg`,
	"contentBodyMidSectionLinkText": `TH13JYdKLQkI6XrFw4y3`
};
export default ___CSS_LOADER_EXPORT___;
