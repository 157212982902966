import React, { useContext } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Grid, makeStyles } from "@material-ui/core";
import TemplateCard from "./template-card/template-card.component";
import LoadingCards from "./loading-cards/loading-cards.component";
import NoTemplateSearchResults from "./no-template-search-results/no-template-search-results.component";
import styles from "./template-cards.styles";
import BaseTemplateCard from "./base-template-card/base-template-card.component";
import { SUGGESTED_CATEGORY_ID } from "../../../app.constants";
import { AppContext } from "../../../app.context";
import { useUserState } from "gx-npm-lib";

const useStyles = makeStyles(() => styles);
const TemplateCards = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { hasUserState } = useUserState();
  const { isLoadingCategories, isSearching, selectedCategoryTemplates, isSearchData, categoryId, isLoadingTemplates } =
    useContext(AppContext);

  const isLoading = isLoadingCategories || isLoadingTemplates || !hasUserState || isSearching;

  return (
    <div className={classNames("cards", classes.container)}>
      {selectedCategoryTemplates && (
        <Grid aria-label={t("Templates Grid")} container spacing={3}>
          {isLoading && <LoadingCards count={selectedCategoryTemplates?.length || 0} />}
          {!isLoading &&
            selectedCategoryTemplates.map((template, index) => {
              return (
                <TemplateCard key={`Template-Card-Item-${template.id}`} isFirstCard={index === 0} template={template} />
              );
            })}

          {!isLoading && !isSearchData && categoryId !== SUGGESTED_CATEGORY_ID && <BaseTemplateCard />}
          {!isSearching && isSearchData && selectedCategoryTemplates.length === 0 && <NoTemplateSearchResults />}
        </Grid>
      )}
    </div>
  );
};

export default TemplateCards;
