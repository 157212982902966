import React from "react";
import { colorPalette } from "gx-npm-common-styles";

const MagnifyGlassIcon = ({ disabled }: { disabled: boolean }) => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1927_3524)">
        <path
          d="M15.4999 13.9996H14.7099L14.4299 13.7296C15.6299 12.3296 16.2499 10.4196 15.9099 8.38965C15.4399 5.60965 13.1199 3.38965 10.3199 3.04965C6.08989 2.52965 2.52989 6.08965 3.04989 10.3196C3.38989 13.1196 5.60989 15.4396 8.38989 15.9096C10.4199 16.2496 12.3299 15.6296 13.7299 14.4296L13.9999 14.7096V15.4996L18.2499 19.7496C18.6599 20.1596 19.3299 20.1596 19.7399 19.7496C20.1499 19.3396 20.1499 18.6696 19.7399 18.2596L15.4999 13.9996ZM9.49989 13.9996C7.00989 13.9996 4.99989 11.9896 4.99989 9.49965C4.99989 7.00965 7.00989 4.99965 9.49989 4.99965C11.9899 4.99965 13.9999 7.00965 13.9999 9.49965C13.9999 11.9896 11.9899 13.9996 9.49989 13.9996Z"
          fill={disabled ? colorPalette.neutrals.stone.hex : colorPalette.neutrals.iron.hex}
        />
      </g>
      <defs>
        <clipPath id="clip0_1927_3524">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MagnifyGlassIcon;
