import { colorPalette } from "gx-npm-common-styles";

const colorQuartz = colorPalette.neutrals.quartz.hex;
const categoryListTop = 72;

const templateSelectionStyles = {
  categoryList: {
    backgroundColor: colorQuartz,
    minWidth: "300px",
    overflow: "hidden",
    position: "sticky" as const,
    top: `${categoryListTop}px`,
    zIndex: 0,
  },
  templateCardDisplay: {
    backgroundColor: colorQuartz,
    height: "100%",
    padding: "24px 72px 0 72px",
    transition: "all 0.5s",
    width: "100%",
    "@media (max-width:1919px)": {
      padding: "24px 56px 0 56px",
    },
    "@media (max-width:1239px)": {
      padding: "24px 24px 0 24px",
    },
  },
  editPersonalizationBtn: {
    float: "right" as const,
    margin: "4px 9px",
  },
};

export { templateSelectionStyles, categoryListTop };
