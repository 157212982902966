import { colorPalette } from "gx-npm-common-styles";

const titleStyles = {
  cardsTitleContainer: {
    color: colorPalette.neutrals.coal.hex,
    display: "block",
    minHeight: "26px",
    paddingBottom: "24px",
  },
  searchTitle: {
    color: colorPalette.neutrals.iron.hex,
  },
  searchTitleTerm: {
    color: colorPalette.neutrals.carbon.hex,
  },
};

export default titleStyles;
