export const suggestedMarketData: Readonly<Record<string, number[]>> = Object.freeze({
  Marketing: [16, 17, 9, 15, 14],
  "Supply Chain": [102, 99, 100, 93, 90],
  Finance: [115, 33, 127, 82, 93],
  Engineering: [63, 33, 8, 1, 97],
  Sales: [5, 1, 3, 7, 2],
  Procurement: [91, 93, 63, 47, 1],
  "Human Resources": [35, 39, 8, 36, 136],
  Other: [91, 63, 87, 33, 8],
  "Product Management": [8, 63, 20, 1, 91],
  "Information Technology": [63, 8, 73, 33, 47],
  Operations: [91, 1, 20, 33, 104],
  "Customer Service": [94, 81, 63, 12, 109],
});
