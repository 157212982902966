import { colorPalette, weightPalette } from "gx-npm-common-styles";

const noTemplateSearchResultsStyles = {
  noResultsContainer: {
    paddingTop: "94px",
    textAlign: "center" as const,
    width: "100%",
  },
  noResultsSubtitle: {
    color: colorPalette.neutrals.iron.hex,
    fontSize: "18px",
    fontVariationSettings: weightPalette.semiBold.variation,
    fontWeight: weightPalette.semiBold.amount,
    lineHeight: "25px",
    letterSpacing: "0.25px",
    paddingTop: "40px",
    textAlign: "center" as const,
  },
  titlePrefix: {
    color: colorPalette.neutrals.carbon.hex,
  },
  titleTerm: {
    color: colorPalette.neutrals.carbon.hex,
  },
  airplaneContainer: {
    bottom: "0",
    position: "absolute" as const,
    right: "0",
  },
  noResultsTitleContainer: {},
};

export default noTemplateSearchResultsStyles;
