import React, { Fragment, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { getAsyncRequest, useCaptureEventsV2, useUserState } from "gx-npm-lib";
import { ErrorOverlayV2, GartnerFooter, SnackbarBanner } from "gx-npm-ui";
import { rootStyles as styles } from "./app.styles";
import * as singleSpa from "single-spa";
import { ClientEvent } from "./app.constants";
import { AppContext } from "./app.context";
import TemplateHeader from "./sections/header/template-header.component";
import TemplateSelection from "./sections/template-selection/template-selection.component";
import { updateStateSubject } from "gx-npm-messenger-util";

const useStyles = makeStyles(() => styles);
const AppComponent = () => {
  if (window.location.pathname.includes("/s/template-hub")) {
    singleSpa.navigateToUrl("/s/markets" + window.location.search + window.location.hash);
  }
  const classes = useStyles();
  const [fatalError, setFatalError] = useState(false);
  const [statusCode, setStatusCode] = useState(200);
  const { hasUserState, hashedUserId, isEntitled, isGartnerAssociate } = useUserState();
  const captureEvents = useCaptureEventsV2();
  const {
    isError,
    setIsLoadingCategories,
    setTemplateData,
    setIsError,
    setIsLoadingTemplates,
    setCategoryDataList,
    setCategoryDataTemplateCount,
    setSelectedCategoryTemplates,
  } = useContext(AppContext);

  useEffect(() => {
    if (!hasUserState) {
      return;
    }

    (async () => {
      setIsLoadingCategories(true);
      const url = `/api/v2/data/template/all${isGartnerAssociate ? "?includeMocks=true" : ""}`;
      const response = await getAsyncRequest(url);
      if (response.status === 200 && response.data.data) {
        setCategoryDataList(response.data.data.categories);
        setCategoryDataTemplateCount(response.data.data.templates.length);
        setTemplateData(response.data.data.templates);
        setSelectedCategoryTemplates(response.data.data.templates);
      } else {
        setStatusCode(response?.status);
        setFatalError(true);
      }
      setIsLoadingCategories(false);
    })();
  }, [
    hasUserState,
    isGartnerAssociate,
    setCategoryDataList,
    setCategoryDataTemplateCount,
    setIsLoadingCategories,
    setIsLoadingTemplates,
    setSelectedCategoryTemplates,
    setTemplateData,
  ]);

  useEffect(() => {
    captureEvents([
      {
        eventType: ClientEvent.USER_CLICKED_TEMPLATE_HUB,
      },
    ]);
  }, [captureEvents]);

  useEffect(() => {
    if (window.FS && hashedUserId) {
      window.FS.event("browse to markets event", { hashedUserId, isEntitled });
    }
  }, [hashedUserId, isEntitled]);

  useEffect(() => {
    if (!window.location.hash) {
      return;
    }
    const hashPath = window.location.hash.split("/");
    if (hashPath[1] !== "template-preview" || !hashPath[2]) {
      return;
    }
    const templateId = Number.parseInt(hashPath[2].split("?")[0]);
    if (!templateId) {
      return;
    }
    const queryParts = hashPath[2].split("?");
    const hashPartQuery = queryParts.length > 1 ? "?" + queryParts[1] : "";
    singleSpa.navigateToUrl(`/s/market/${templateId}/preview${hashPartQuery}`);
    updateStateSubject("TEMPLATE_PREVIEW", {
      templateId,
      display: true,
      isDialog: true,
    });
  }, []);

  return (
    <Fragment>
      <div className={classNames(classes.appContainer)}>
        {!fatalError && (
          <Fragment>
            <TemplateHeader />
            <div className={classNames(classes.templateContainer)}>
              <TemplateSelection />
            </div>
            <SnackbarBanner
              autoHideDuration={5000}
              isOpen={isError}
              isDefaultErrorMessage={true}
              isTimeoutAllowed={true}
              setIsOpen={setIsError}
              type="ERROR"
            />
          </Fragment>
        )}
        {fatalError && <ErrorOverlayV2 statusCode={statusCode} />}
      </div>
      <GartnerFooter />
    </Fragment>
  );
};

export default AppComponent;
