import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { Loader } from "gx-npm-ui";
import { loaderStyles as styles } from "./filter-loader.styles";

const useStyles = makeStyles(() => styles);
const FilterLoader = () => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.loaderContainer)}>
      <Loader rootClassName={"filter-loader"} />
    </div>
  );
};

export default FilterLoader;
