import React, { useContext } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Grid, makeStyles } from "@material-ui/core";
import * as singleSpa from "single-spa";
import {
  ButtonLoader,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Header,
  Paragraph,
  TypographyComponent,
} from "gx-npm-ui";
import styles from "./template-card.styles";
import { Template } from "../../../../app.types";
import { AppContext } from "../../../../app.context";
import { SUGGESTED_CATEGORY_ID } from "../../../../app.constants";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

type TemplateCardProps = {
  template: Template;
  isFirstCard: boolean;
};

const TemplateCard = (props: TemplateCardProps) => {
  const useStyles = makeStyles(() => styles);
  const classes = useStyles();
  const { t } = useTranslation();
  const { categoryName, categoryId, isOtherTemplateDisabled } = useContext(AppContext);
  const { template, isFirstCard } = props;

  const handleClickViewMarket = () => {
    localStorage.setItem("showRibbonAnimation", "true");
    singleSpa.navigateToUrl(`/s/market/${template.id}`);
  };
  return (
    <Grid item md={12} lg={6} xl={6}>
      <div>
        <div className={classNames("card", classes.container)}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <div className={classNames("gx-temp-card-body", classes.bodyContainer)}>
                <TypographyComponent
                  styling={"p4"}
                  color={"iron"}
                  boldness={"medium"}
                  rootClassName={classNames(classes.GCOM3606TypeTitle, categoryName)}
                >
                  {![SUGGESTED_CATEGORY_ID, 0].includes(categoryId) ? categoryName : ""}
                </TypographyComponent>
                <TypographyComponent
                  styling={"h5"}
                  rootClassName={classNames(classes.GCOM3606tech)}
                  boldness={"medium"}
                  color={"carbon"}
                >
                  {template.name}
                </TypographyComponent>
                <TypographyComponent
                  rootClassName={classes.marketDescription}
                  styling={"p3"}
                  color={"coal"}
                  boldness={"regular"}
                >
                  {template.description}
                </TypographyComponent>
              </div>

              <div className={classNames(classes.footer)}>
                <div className={classes.templateContent}>
                  <TypographyComponent color={"iron"} styling={"p4"} boldness={"regular"}>
                    {t("Template content for this market")}
                  </TypographyComponent>

                  <ul className={classes.templateContentList}>
                    <li className={classes.templateContentListItem}>
                      <TypographyComponent element={"span"} boldness={"semi"} styling={"p3"}>
                        {template.productCount}
                      </TypographyComponent>
                      <TypographyComponent color={"iron"} styling={"p4"} element={"span"} boldness={"regular"}>
                        {" "}
                        {t("Vendors")}
                      </TypographyComponent>
                    </li>
                    <li className={classes.templateContentListItem}>
                      <TypographyComponent element={"span"} boldness={"semi"} styling={"p3"}>
                        {template.requirementCount}
                      </TypographyComponent>
                      <TypographyComponent color={"iron"} styling={"p4"} element={"span"} boldness={"regular"}>
                        {" "}
                        {t("Requirements")}
                      </TypographyComponent>
                    </li>
                  </ul>
                </div>
                <ButtonLoader
                  btnClass="btn-secondary"
                  onClick={handleClickViewMarket}
                  disabled={isOtherTemplateDisabled}
                  rootClassName={classNames("gx-card-action", isFirstCard && "gx-first-view-market-button")}
                >
                  {t("View Market")}
                </ButtonLoader>
              </div>
            </FeatureFlagBooleanOn>

            <FeatureFlagBooleanOff>
              <div className={classNames("gx-temp-card-body", classes.bodyContainer)}>
                <div className={classNames(classes.typeTitle, categoryName)}>
                  {![SUGGESTED_CATEGORY_ID, 0].includes(categoryId) ? categoryName : ""}
                </div>
                <Header boldness="semi" type="h5" rootClassName={classes.tech}>
                  {template.name}
                </Header>
                <Paragraph boldness="regular" type="p3" rootClassName={classes.description}>
                  {template.description}
                </Paragraph>
              </div>
              <div className={classNames(classes.footer)}>
                <div className={classes.templateContent}>
                  <Paragraph boldness="regular" type="p4" rootClassName={classes.templateContentTitle}>
                    {t("Template content for this market")}
                  </Paragraph>
                  <ul className={classes.templateContentList}>
                    <li className={classes.templateContentListItem}>
                      <span className={classes.templateItemCount}>{template.productCount}</span> {t("Vendors")}
                    </li>
                    <li className={classes.templateContentListItem}>
                      <span className={classes.templateItemCount}>{template.requirementCount}</span> {t("Requirements")}
                    </li>
                  </ul>
                </div>
                <ButtonLoader
                  btnClass="btn-secondary"
                  onClick={handleClickViewMarket}
                  disabled={isOtherTemplateDisabled}
                  rootClassName={classNames("gx-card-action", isFirstCard && "gx-first-view-market-button")}
                >
                  {t("View Market")}
                </ButtonLoader>
              </div>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
      </div>
    </Grid>
  );
};

export default TemplateCard;
