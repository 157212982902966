import React, { useContext, useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { BoxOutlineIcon } from "gx-npm-icons";
import { buttonStyles as styles } from "./filter-button.style";
import { AppContext } from "../../../../app.context";
import { ClientEvent, SUGGESTED_CATEGORY_ID, SUGGESTED_CATEGORY_TITLE } from "../../../../app.constants";
import { suggestedMarketData } from "../../../../lib/suggested-market-data";
import { useCaptureEventsV2, useUserState } from "gx-npm-lib";
import { useTranslation } from "react-i18next";
import { storeFullStorySearchBrowseEvent } from "../../../../lib/full-story-events.lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

const useStyles = makeStyles(() => styles);

type FilterButtonProps = {
  count: number;
  isSelected: boolean;
  name: string;
  iconLoc?: string;
  id: number;
};

const FilterButton = (categoryFilterProps: FilterButtonProps) => {
  const captureEvents = useCaptureEventsV2();
  const { id, name, isSelected, iconLoc, count } = categoryFilterProps;
  const classes = useStyles();
  const [displayDefaultIcon, setDisplayDefaultIcon] = useState(false);
  const { t } = useTranslation();
  const {
    setCategoryId,
    templateData,
    setSelectedCategoryTemplates,
    setIsSearchData,
    setCategoryName,
    categoryId,
    categoryName,
  } = useContext(AppContext);
  const { profile } = useUserState();
  const handleImageLoadingError = () => setDisplayDefaultIcon(true);
  const handleClick = () => {
    setCategoryId(id || 0);
    setCategoryName(id === SUGGESTED_CATEGORY_ID ? `${t(SUGGESTED_CATEGORY_TITLE)}` : categoryFilterProps?.name || "");
    setIsSearchData(false);
    window.scrollTo({ top: 0 });
    updateSelectedCategories();
  };

  const updateSelectedCategories = () => {
    if (id === SUGGESTED_CATEGORY_ID) {
      captureEvents([
        {
          eventType: ClientEvent.MARKETS_SUGGESTED_CATEGORY_CLICKED,
        },
      ]);
      if (profile?.role) {
        setSelectedCategoryTemplates(
          templateData.filter((template) => suggestedMarketData[profile.role]?.includes(template.id))
        );
      }
    } else {
      // adding missed out Full story event for category selections
      storeFullStorySearchBrowseEvent({
        categoryId: categoryId,
        categoryName: categoryName,
        isSearchData: false,
        searchResultsCount: 0,
        searchTerm: "",
      });
      setSelectedCategoryTemplates(
        id === 0 ? templateData : templateData?.filter((template) => template.categoryList?.includes(id))
      );
    }
  };

  const renderIcon = () => {
    return iconLoc && !displayDefaultIcon ? (
      <img
        src={iconLoc}
        className={id !== SUGGESTED_CATEGORY_ID ? "gx-filter-icon" : "gx-filter-suggest-icon"}
        alt="filter icon"
        onError={handleImageLoadingError}
      />
    ) : (
      <BoxOutlineIcon />
    );
  };
  return (
    <button
      className={classNames("gx-filter-button", "medium-bold", classes.filterButton, isSelected && "selected")}
      onClick={handleClick}
      aria-label={name}
    >
      <div className={classNames(classes.btnDisplay)}>
        <div className={classNames(classes.btnChild, classes.btnInd)} />
        <div className={classNames(classes.btnChild, classes.btnIcon)}>{renderIcon()}</div>
        <div className={classNames(classes.btnChild, classes.btnName)}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent element={"span"} color={"inherit"} boldness={"medium"}>
                {name}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>{name}</FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
        <div className={classNames(classes.btnChild, classes.btnCont)}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent
                element={"span"}
                rootClassName={classNames(classes.btnCntValue)}
                boldness={"semi"}
                styling={"p3"}
                color={"inherit"}
              >
                {count}
              </TypographyComponent>
            </FeatureFlagBooleanOn>

            <FeatureFlagBooleanOff>
              <span className={classNames(classes.btnCntValue, "semi-bold")}>{count}</span>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
      </div>
    </button>
  );
};

export default FilterButton;
