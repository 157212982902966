// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KE5pJ_grEN7H3WsqVwGB .gx-dialog-body{margin-left:-36px;width:600px}.Dohn2ZDvxfPWtUplWx72{padding:24px 36px 56px 36px}.V_Iw0KlTUhI00PhHic_a{background:var(--color-interactions-light-berry);padding:56px 0 74px 36px}.V_Iw0KlTUhI00PhHic_a .dld2doOGLUIUga0CyuKg{display:block}.V_Iw0KlTUhI00PhHic_a .XxiObHCpc6PKCIoil989{position:relative}.V_Iw0KlTUhI00PhHic_a .XxiObHCpc6PKCIoil989 .lp9lSaNGvndMaCj2XGrr{bottom:-74px;position:absolute;right:0}.SBDugseYnCbULqnpu4xF{align-items:center;display:flex;justify-content:flex-end}.SBDugseYnCbULqnpu4xF .twRW0nDiXY2ufTo1dvnT{margin-right:24px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/free-trial/free-trial-init-create-modal/free-trial-init-create-modal.styles.module.scss"],"names":[],"mappings":"AACE,sCACE,iBAAA,CACA,WAAA,CAIJ,sBACE,2BAAA,CAGF,sBACE,gDAAA,CACA,wBAAA,CAEA,4CACE,aAAA,CAEF,4CACE,iBAAA,CAEA,kEACE,YAAA,CACA,iBAAA,CACA,OAAA,CAKN,sBACE,kBAAA,CACA,YAAA,CACA,wBAAA,CAEA,4CACE,iBAAA","sourcesContent":[".dialogRoot {\n  & :global(.gx-dialog-body) {\n    margin-left: -36px;\n    width: 600px;\n  }\n}\n\n.subtitleParagraph {\n  padding: 24px 36px 56px 36px\n}\n\n.remainingInitsHeading {\n  background: var(--color-interactions-light-berry);\n  padding: 56px 0 74px 36px;\n\n  & .headingSecondLine {\n    display: block;\n  }\n  & .cornerImgContainer {\n    position: relative;\n\n    & .cornerImg {\n      bottom: -74px;\n      position: absolute;\n      right: 0;\n    }\n  }\n}\n\n.footerButtonContainer {\n  align-items: center;\n  display: flex;\n  justify-content: flex-end;\n\n  & .cancelButton {\n    margin-right: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogRoot": `KE5pJ_grEN7H3WsqVwGB`,
	"subtitleParagraph": `Dohn2ZDvxfPWtUplWx72`,
	"remainingInitsHeading": `V_Iw0KlTUhI00PhHic_a`,
	"headingSecondLine": `dld2doOGLUIUga0CyuKg`,
	"cornerImgContainer": `XxiObHCpc6PKCIoil989`,
	"cornerImg": `lp9lSaNGvndMaCj2XGrr`,
	"footerButtonContainer": `SBDugseYnCbULqnpu4xF`,
	"cancelButton": `twRW0nDiXY2ufTo1dvnT`
};
export default ___CSS_LOADER_EXPORT___;
