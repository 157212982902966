import { colorPalette } from "gx-npm-common-styles";

const colorIron = colorPalette.neutrals.iron.hex;
const colorQuartz = colorPalette.neutrals.quartz.hex;
const colorWhite = colorPalette.basic.white.hex;

export const filterFooterStyles = {
  filterFooter: {
    backgroundColor: colorWhite,
    borderTop: `2px ${colorQuartz} solid`,
    bottom: 0,
    color: colorIron,
    paddingBottom: "16px",
    paddingTop: "16px",
    position: "sticky" as const,
    textAlign: "center" as const,
    width: "100%",
  },
  filterFooterDesc: {
    paddingBottom: "8px",
  },
};
