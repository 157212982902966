import React from "react";
import classNames from "classnames";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Loader } from "gx-npm-ui";
import styles from "./load-card.styles";

const useStyles = makeStyles(() => styles);
const LoadCard = () => {
  const classes = useStyles();
  return (
    <Grid item md={12} lg={6} xl={6}>
      <div className={classNames(classes.container)}>
        <Box alignItems="center" className={classes.loadingBox} display="flex" justifyContent="center">
          <Loader />
        </Box>
      </div>
    </Grid>
  );
};

export default LoadCard;
