import { colorPalette } from "gx-npm-common-styles";

const headerClasses = {
  container: {
    alignItems: "center",
    background: `linear-gradient(90deg, ${colorPalette.brand.gartnerDarkBlue.hex} 0%, ${colorPalette.brand.gartnerBlue.hex} 100%)`,
    display: "flex",
    height: 72,
    justifyContent: "space-between",
    minWidth: "950px",
    padding: "20px 32px 21px",
    position: "sticky" as const,
    top: 0,
    width: "100%",
    zIndex: 1000,
  },
  title: {
    color: colorPalette.basic.white.hex,
    zIndex: 1000,
  },
  description: {
    color: colorPalette.neutrals.silver.hex,
  },
  cornerImage: {
    position: "absolute" as const,
    right: 250,
    top: "0",
  },
  infoButton: {
    backgroundColor: colorPalette.brand.yam.hex,
    color: colorPalette.basic.white.hex,
    marginRight: 24,
    textTransform: "none" as const,
    "@media (max-width:1239px)": {
      marginRight: -8,
    },
    "@media (min-width:1920px)": {
      marginRight: 40,
    },
    "&:hover": {
      backgroundColor: colorPalette.brand.spice.hex,
    },
    "& svg": {
      marginRight: 8,
    },
  },
};

export default headerClasses;
