import { colorPalette } from "gx-npm-common-styles";

const colorQuartz = colorPalette.neutrals.quartz.hex;

const rootStyles = {
  appContainer: {
    backgroundColor: colorQuartz,
    color: colorPalette.basic.black.hex,
    transition: "all 0.5s !important",
    width: "100%",
    paddingLeft: "76px",
    "@media (max-width: 1023px)": {
      width: "1024px",
    },
  },
  templateContainer: {
    display: "flex",
    flexDirection: "row" as const as "row",
    justifyContent: "flex-start",
    width: "100%",
  },
  "@global": {
    body: {
      backgroundColor: `${colorQuartz} !important`,
    },
  },
};

export { rootStyles };
