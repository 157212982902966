import { colorPalette } from "gx-npm-common-styles";

const colorBlueBerry = colorPalette.interactions.blueBerry.hex;
const colorCarbon = colorPalette.neutrals.carbon.hex;
const colorIron = colorPalette.neutrals.iron.hex;
const colorQuartz = colorPalette.neutrals.quartz.hex;
const colorWhite = colorPalette.basic.white.hex;

const filterFooterStyles = {
  filterFooter: {
    backgroundColor: colorWhite,
    borderTop: `2px ${colorQuartz} solid`,
    bottom: 0,
    color: colorIron,
    paddingBottom: "16px",
    paddingTop: "16px",
    position: "sticky" as const,
    textAlign: "center" as const,
    width: "100%",
  },
  filterFooterDesc: {
    paddingBottom: "8px",
  },
};

const filterHeaderStyles = {
  filterTitle: {
    color: colorCarbon,
    padding: "25px 28px",
  },
  titleBorder: {
    borderBottom: `2px ${colorQuartz} solid`,
    marginBottom: "12px",
    "&.gx-template-hub-filter-header-sticky": {
      backgroundColor: colorWhite,
      borderBottom: 0,
      boxShadow: "0px 3px 3px 1px rgba(20, 19, 18, 0.1)",
      marginBottom: 0,
      position: "sticky" as const,
      top: 0,
    },
  },
};

const templateFilterStyles = {
  filterContainer: {
    backgroundColor: colorWhite,
    height: "100%",
    overflowX: "hidden" as const,
    overflowY: "auto" as const,
    paddingBottom: "24px",
    "&.gx-template-hub-filter-body-sticky": {
      paddingTop: "12px",
    },
  },
};

const loaderStyles = {
  loaderContainer: {
    height: "100%",
    margin: "auto",
    width: "100%",
  },
};

const buttonStyles = {
  filterButton: {
    backgroundColor: colorWhite,
    border: "none",
    color: colorIron,
    display: "block",
    fontSize: "14px",
    height: "40px",
    letterSpacing: "0.25px",
    lineHeight: "21px",
    padding: 0,
    textAlign: "left" as const,
    width: "100%",
    "& img.gx-filter-icon": {
      // We can generate this filter value by using the following link. https://codepen.io/sosuke/pen/Pjoqqp
      filter:
        "brightness(0) saturate(100%) invert(58%) sepia(11%) saturate(38%) hue-rotate(329deg) brightness(93%) contrast(91%)",
    },
    "&:hover, &:focus": {
      color: colorCarbon,
      cursor: "pointer",
      "& $btnCont": {
        visibility: "visible" as const,
      },
      "& svg path": {
        fill: colorCarbon,
      },
      "& img.gx-filter-icon": {
        filter:
          "brightness(0) saturate(100%) invert(3%) sepia(11%) saturate(430%) hue-rotate(349deg) brightness(92%) contrast(91%)",
      },
    },
    "&.selected, &.selected:hover, &.selected:focus": {
      color: colorBlueBerry,
      "& $btnCont": {
        backgroundColor: colorPalette.interactions.lightBerry.hex,
        visibility: "visible" as const,
      },
      "& $btnInd": {
        backgroundColor: colorBlueBerry,
        visibility: "visible" as const,
      },
      "& svg path": {
        fill: colorBlueBerry,
      },
      "& img.gx-filter-icon": {
        filter:
          "brightness(0) saturate(100%) invert(13%) sepia(98%) saturate(4423%) hue-rotate(212deg) brightness(98%) contrast(105%)",
      },
    },
  },
  btnDisplay: {
    display: "flex",
    alignItems: "center",
  },
  btnChild: {
    display: "inline-flex",
  },
  btnIcon: {
    marginLeft: "20px",
    width: "22px",
    justifyContent: "center",
  },
  btnName: {
    paddingLeft: "12px",
    textAlign: "left" as const,
    flexGrow: 1,
  },
  btnCont: {
    backgroundColor: colorQuartz,
    height: "30px",
    margin: "0 24px 0 12px",
    textAlign: "center" as const,
    visibility: "hidden" as const,
    width: "30px",
  },
  btnCntValue: {
    margin: "auto",
  },
  btnInd: {
    height: "40px",
    visibility: "hidden" as const,
    width: "8px",
  },
};

export { buttonStyles, filterFooterStyles, filterHeaderStyles, templateFilterStyles, loaderStyles };
