import React, { useEffect } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { createGenerateClassName, StylesProvider } from "@material-ui/core/styles";
import { commonTheme } from "gx-npm-common-styles";
import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagProvider, FeatureFlagsAvailableContainer } from "gx-npm-ui";
import AppComponent from "./app.component";
import { navScrollTimer } from "./lib";
import { AppContextProvider } from "./app.context";

const generateClassName = createGenerateClassName({
  seed: "Template-Hub-App",
});

const theme = createMuiTheme(commonTheme);

export default function Root() {
  //This section is used to handle moving the screen to the left when under 1024. avoids rendering behind left nav bar.
  useEffect(() => {
    const interval = navScrollTimer();
    return () => clearTimeout(interval);
  }, []);

  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <ThemeProvider theme={theme}>
        <StylesProvider generateClassName={generateClassName}>
          <FeatureFlagsAvailableContainer>
            <AppContextProvider>
              <AppComponent />
            </AppContextProvider>
          </FeatureFlagsAvailableContainer>
        </StylesProvider>
      </ThemeProvider>
    </FeatureFlagProvider>
  );
}
