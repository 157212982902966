import React, { Fragment, useLayoutEffect, useRef, useContext, useEffect } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { categoryListTop, templateSelectionStyles as styles } from "./template-selection.style";
import ProfileFormComponent from "./profile-form/profile-form.component";
import { AppContext } from "../../app.context";
import { MiniButton } from "gx-npm-ui";
import { EditPencilIcon } from "gx-npm-icons";
import { useTranslation } from "react-i18next";
import { SUGGESTED_CATEGORY_ID } from "../../app.constants";
import { colorPalette } from "gx-npm-common-styles";
import TemplateCards from "./template-cards/template-cards.component";
import SuggestedMarketBanner from "./suggested-market-banner/suggested-market-banner.component";
import { useUserState } from "gx-npm-lib";
import TemplateCardsTitle from "./template-cards-title/template-cards-title.component";
import TemplateFilter from "./template-filter/template-filter.component";

const useStyles = makeStyles(() => styles);
const TemplateSelection = () => {
  const { categoryId, showPersonalisationForm, setShowPersonalisationForm } = useContext(AppContext);
  const classes = useStyles();
  const categoryList = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const { profileComplete } = useUserState();

  useLayoutEffect(() => {
    const updateSize = () => {
      const elem = categoryList?.current;
      (elem as unknown as HTMLElement).setAttribute("style", `height: ${window.innerHeight - categoryListTop}px`);
    };
    updateSize();
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    setShowPersonalisationForm(categoryId === SUGGESTED_CATEGORY_ID && !profileComplete);
  }, [profileComplete, categoryId, setShowPersonalisationForm]);

  return (
    <Fragment>
      <div className={classNames("gx-category-list", classes.categoryList)} ref={categoryList}>
        <TemplateFilter />
      </div>
      {showPersonalisationForm && <ProfileFormComponent />}
      {!showPersonalisationForm && (
        <div className={classNames(classes.templateCardDisplay)}>
          {categoryId === SUGGESTED_CATEGORY_ID && (
            <MiniButton rootClassName={classes.editPersonalizationBtn} onClick={() => setShowPersonalisationForm(true)}>
              <EditPencilIcon fillPath={colorPalette.interactions.blueBerry.hex} />
              {t("Edit personalization settings")}
            </MiniButton>
          )}
          {!profileComplete && categoryId === 0 && <SuggestedMarketBanner />}
          <TemplateCardsTitle />
          <TemplateCards />
        </div>
      )}
    </Fragment>
  );
};
export default TemplateSelection;
