export enum ClientEvent {
  USER_CLICKED_TEMPLATE_HUB = "USER_CLICKED_TEMPLATE_HUB",
  MARKETS_SEARCH_BY_KEYWORD = "MARKETS_SEARCH_BY_KEYWORD",
  MARKETS_SUGGESTED_CATEGORY_CLICKED = "MARKETS_SUGGESTED_CATEGORY_CLICKED",
}

export const SUGGESTED_CATEGORY_TITLE = "Suggested markets for you";
export const SUGGESTED_CATEGORY_ID = 999;

export const EMQ_CAT_ID = 20;
export const EMQ_TEMPLATE_IDS = Object.freeze([306, 307, 308]);
