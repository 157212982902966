import React, { RefObject, useContext, useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { BaseTemplateIcon } from "gx-npm-icons";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  MiniButton,
  Paragraph,
  TooltipV2,
  TypographyComponent,
} from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import createBaseTemplateInitiative from "../../../../lib/create-base-template-initiative.lib";
import { filterFooterStyles as styles } from "./filter-footer.style";
import { AppContext } from "../../../../app.context";
import { useUserState } from "gx-npm-lib";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

type FilterFooterProps = {
  forwardedRef: RefObject<HTMLDivElement> | null;
};

const useStyles = makeStyles(() => styles);
const FilterFooter = (props: FilterFooterProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isDisabled, setDisabled] = useState(false);
  const { isSearchData, templateData, searchTerm, setIsError } = useContext(AppContext);
  const { isEntitled } = useUserState();

  const handleClickV2 = async () => {
    const config = {
      categoryId: 0,
      categoryName: "",
      isSearchData,
      searchResultsCount: templateData?.length,
      searchTerm,
    };
    await createBaseTemplateInitiative(config, () => setIsError(true), setDisabled);
  };

  return (
    <div className={classes.filterFooter} ref={props.forwardedRef}>
      <div className={classes.filterFooterDesc}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent styling={"p3"} color={"iron"}>
              {t("Can’t find the market you need?")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <Paragraph type="p3">{t("Can’t find the market you need?")}</Paragraph>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
      <TooltipV2
        placement="top"
        PopperProps={{
          modifiers: {
            offset: {
              offset: "0, 10px",
            },
          },
        }}
        title={
          isEntitled
            ? t(
                "Use our base template for any technology evaluation to access all the same features to manage your evaluation, including a comprehensive checklist, universal requirements, questionnaire, evaluation scorecard and selection summary."
              )
            : t(
                "Your account does not have permission to create initiatives. Please contact your Gartner Account Manager for assistance."
              )
        }
      >
        <div>
          <MiniButton
            ariaLabel="base template button"
            disabled={isDisabled || !isEntitled}
            onClick={handleClickV2}
            rootClassName={classNames(
              "gx-filter-use-base-template",
              "gx-intercom-filter-use-base-template, gx-start-from-scratch-button"
            )}
          >
            <React.Fragment>
              <BaseTemplateIcon />
              {t("Start eval from scratch")}
            </React.Fragment>
          </MiniButton>
        </div>
      </TooltipV2>
    </div>
  );
};

export default FilterFooter;
