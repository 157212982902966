const clearSearchButtonStyles = {
  clearButtonContainer: {
    marginRight: "8px",
    width: "24px",
    "& .gx-search-clear-icon-button": {
      background: "unset",
      border: "unset",
      cursor: "pointer",
      height: "24px",
      marginRight: "8px",
      padding: "unset",
      width: "24px",
      "&:disabled": {
        cursor: "unset",
      },
    },
  },
};

export default clearSearchButtonStyles;
