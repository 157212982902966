import React from "react";
import { colorPalette } from "gx-npm-common-styles";

const ClearSearchXIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9993 8.00677C15.6651 7.67256 15.1247 7.6725 14.7904 8.00658L12.0002 10.7911L9.20977 8.0007C8.87551 7.66643 8.33496 7.66643 8.0007 8.0007C7.66643 8.33496 7.66643 8.87551 8.0007 9.20977L10.7909 12L8.0007 14.7902C7.66643 15.1245 7.66643 15.665 8.0007 15.9993C8.33496 16.3336 8.87551 16.3336 9.20977 15.9993L12 13.2091L14.7902 15.9993C15.1245 16.3336 15.665 16.3336 15.9993 15.9993C16.3336 15.665 16.3336 15.1245 15.9993 14.7902L13.2091 12L15.9993 9.20977C16.3275 8.88157 16.3275 8.33496 15.9993 8.00677Z"
        fill={colorPalette.neutrals.iron.hex}
      />
    </svg>
  );
};

export default ClearSearchXIcon;
