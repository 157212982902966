import { colorPalette, weightPalette } from "gx-npm-common-styles";

const baseTemplateCardStyles = {
  container: {
    backgroundColor: colorPalette.neutrals.silver.hex,
    borderColor: colorPalette.basic.gray.hex,
    borderRadius: 8,
    borderWidth: 1,
    boxSizing: "border-box" as const,
    color: colorPalette.basic.black.hex,
    display: "flex",
    flexDirection: "column" as const,
    fontSize: "14px",
    height: "260px",
    justifyContent: "space-between",
    padding: "32px",
    transition: "all 0.5s",
    width: "100%",
    "@media (max-width: 1239px)": {
      height: "273px",
    },
    "@media (min-width: 1240px) and (max-width: 1439px)": {
      height: "394px",
    },
    "@media (min-width: 1440px) and (max-width: 1919px)": {
      height: "294px",
    },
    "@media (min-width: 1920px)": {
      height: "260px",
    },
  },
  bodyContainer: {
    // overflow is specifically not hidden in the body of the cards per GCOM-498
    overflow: "visible",
  },
  category: {
    color: colorPalette.basic.textSecondary.rgba,
    paddingBottom: 8,
  },
  footer: {
    display: "flex",
    justifyContent: "end",
    "@media (min-width: 1240px)": {
      "& .gx-btn-loader": {
        marginLeft: 24,
        padding: "0 !important",
      },
    },
    "& .gx-btn-loader": {
      display: "inline-flex",
      margin: "auto",
      marginRight: 0,
      "&.gx-card-action": {
        marginLeft: "24px",
        padding: "0px !important",
      },
    },
  },
  templateName: {
    fontVariationSettings: weightPalette.semiBold.variation,
    fontWeight: weightPalette.semiBold.amount,
  },
  tech: {
    color: colorPalette.neutrals.carbon.hex,
    marginBottom: "12px",
  },
  GCOM3606Tech: {
    marginBottom: "12px",
  },
  description: {
    color: colorPalette.neutrals.coal.hex,
  },
};

export { baseTemplateCardStyles };
