import React, { Fragment } from "react";
import LoadingCard from "./load-card/load-card.component";

const maxCount = 20;
const LoadingCards = ({ count = maxCount }: { count: number }) => {
  const cards = [];
  const cardCount = count > 0 ? count : maxCount;
  for (let idx = 0; idx < cardCount; idx++) {
    cards.push(<LoadingCard key={idx + 1} />);
  }
  return <Fragment>{cards}</Fragment>;
};
export default LoadingCards;
