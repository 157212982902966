import { colorPalette } from "gx-npm-common-styles";

const loadCardStyles = {
  container: {
    backgroundColor: colorPalette.basic.white.hex,
    borderColor: colorPalette.basic.gray.hex,
    borderRadius: 8,
    borderWidth: 1,
    boxSizing: "border-box" as const,
    color: colorPalette.basic.black.hex,
    display: "flex",
    flexDirection: "column" as const,
    fontSize: "14px",
    height: "260px",
    justifyContent: "space-between",
    padding: "24px 32px",
    transition: "all 0.5s",
    width: "100%",
    "@media (max-width: 1239px)": {
      height: "273px",
    },
    "@media (min-width: 1240px) and (max-width: 1439px)": {
      height: "364px",
    },
    "@media (min-width: 1440px) and (max-width: 1919px)": {
      height: "294px",
    },
    "@media (min-width: 1920px)": {
      height: "260px",
    },
  },
  loadingBox: {
    height: "100%",
    width: "100%",
    "& .MuiCircularProgress-root": {
      position: "unset",
    },
  },
};

export default loadCardStyles;
