import { postAsyncRequest } from "gx-npm-lib";
import * as singleSpa from "single-spa";
import { reportFullStoryTemplateCreateEvent } from "./full-story-events.lib";

const BASE_TEMPLATE_ID = -1;
type CreateBaseTemplateInitiativeConfig = {
  categoryId: number;
  categoryName: string;
  searchResultsCount: number;
  isSearchData: boolean;
  searchTerm: string;
};

const createBaseTemplateInitiative = async (
  config: CreateBaseTemplateInitiativeConfig,
  onError = (_error: object) => {},
  onLoading = (_isLoading: boolean) => {}
) => {
  async function postData() {
    onLoading(true);
    const url = "/api/v3/initiatives/";
    const payload = { templateId: BASE_TEMPLATE_ID };
    const response = await postAsyncRequest(url, payload);
    const initiativeId = response?.data?.data?.initiativeId || "";
    if (initiativeId) {
      reportFullStoryTemplateCreateEvent({ ...config, id: BASE_TEMPLATE_ID, name: "" });
      localStorage.setItem("showRibbonAnimation", "true");
      singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/overview`);
    } else {
      onError(response);
    }
    onLoading(false);
  }
  await postData();
};

export default createBaseTemplateInitiative;
