import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { IconButton, TooltipV2 } from "gx-npm-ui";
import ClearSearchXIcon from "../../../../../../assets/icons/clear-search-x.icon";
import styles from "./clear-search-button-with-tooltip.styles";

const useStyles = makeStyles(() => styles);
const ClearSearchButtonWithTooltip = ({ onClearSearch }: { onClearSearch: () => void }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TooltipV2
      enterDelay={400}
      enterNextDelay={400}
      placement="top"
      title={t("Clear search")}
      PopperProps={{ modifiers: { offset: { offset: "0, 8px" } } }}
    >
      <div className={classes.clearButtonContainer}>
        <IconButton
          ariaLabel={t("clear search")}
          borderRadius={"12px"}
          className="gx-search-clear-icon-button"
          onClick={onClearSearch}
        >
          <ClearSearchXIcon />
        </IconButton>
      </div>
    </TooltipV2>
  );
};

export default ClearSearchButtonWithTooltip;
